import type { Fetcher, Key } from 'swr';
import type {
  AppContextInterface,
  CategoryMinimalRequestProps,
  CategoryRequestResultProps,
  GameRequestProps,
} from '../../types';

import axios from 'axios';
import * as React from 'react';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';
import { mappingCollection, mappingCollectionMinimal, mappingGame } from '../../app/mappings';
import { themeOptions } from '../../app/theme/theme-options';
import { CollectionTypesEnum } from '../../types/enum';
import { componentOptions } from './component-options';
import { customOptions } from './custom-options';
import { pageOptions } from './page-options';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const basePath = publicRuntimeConfig.basePath || '';

// Only include config options that are, or can be, useful for our components.
// Anything used on server side only is pointless to add here and should be added as environment variable instead.

const config = Object.freeze({
  // Options here are immediately updated, as the value's come from the associated nunjucks template.
  ...customOptions,
  componentOptions,
  pageOptions,
  themeOptions,

  // These options will require staging to be recreated, as their values come from environment variables.
  code: `${process.env.PROJECT_CODE}`,
  id: `${process.env.PROJECT_ID}`,
  name: `${process.env.PROJECT_TITLE}`,
  url: `${process.env.PROJECT_URL}`,
  assetUrl: process.env.PROJECT_ASSET_URL || 'https://media.byorbit.com/asset/', // Need to set this default for Storybook.
  assetAbsoluteUrl: `${process.env.PROJECT_ASSET_ABSOLUTE_URL}`,
  devMode: `${process.env.PROJECT_ASSET_ORIGINAL_DOMAIN}`.includes('dev'),
});

// useSWR default request fetcher using Axios.
export const fetcher: Fetcher<[]> = (url: Key, language = "en") =>
  axios
    .get(`${basePath}${process.env.PROJECT_API_URL}${url}`, {
      headers: {
        Accept: 'application/json',
        'X-Project-Id': process.env.PROJECT_ID || '',
        'X-Language': language,
      },
    })
    .then((res) => res.data);

const AppContext = React.createContext({});

export const useAppContext = (): AppContextInterface => {
  const context = React.useContext(AppContext);

  return context as AppContextInterface;
};

export const AppContextProvider = (props: any) => {
  const [keyword, setKeyword] = React.useState<string | undefined>(undefined);
  const [fullScreen, setFullscreen] = React.useState<boolean>(false);
  const [currentCollection, setCurrentCollection] = React.useState<{
    name: string;
    slug: string;
  }>({
    name: '',
    slug: '',
  });

  // Todo: HUZ2ERA-1110 - The AppContextProvider seems to being re-called on every route change. Some kind of NextJS problem?
  // console.log('CALL:', 'AppContextProvider');

  const useConfig = () => {
    return config;
  };

  const useUser = () => {
    const { data: user, error } = useSWR<object>('profiles/me', fetcher);

    return {
      user,
      isLoading: !error && !user,
      isError: error,
    };
  };

  const useCategories = () => {
    const { data: categories, error } = useSWRImmutable<[]>('categories/list', fetcher);

    return {
      categories:
        categories?.map((collection: CategoryMinimalRequestProps) => ({
          ...mappingCollectionMinimal(collection, CollectionTypesEnum.CATEGORY),
        })) || [],
      isLoading: !error && !categories,
      isError: error,
    };
  };

  const useCollections = (locales: string) => {
    const { data } = useSWRImmutable<[]>(
      ['collections/list-with-games?_fieldsGame=image&_gameLimit=12&_limit=12&', locales],
      // @ts-ignore
      ([url, language]) => fetcher(url, language)
    );

    return {
      collections:
        Array.isArray(data) &&
        data.map((collection: CategoryRequestResultProps) => ({
          ...mappingCollection(collection),
        })),
    };
  };

  const useSearch = () => {
    const { data: results, error } = useSWRImmutable<[]>(
      typeof keyword !== 'undefined' ? `search/find?keyword=${keyword}` : null,
      fetcher
    );

    return {
      results: results?.map((game: GameRequestProps) => ({ ...mappingGame(game) })),
      isLoading: Boolean(keyword) && !error && !results,
      isError: error,
      setKeyword,
    };
  };

  const useSeo = () => {
    const { data: seo, error } = useSWR<object>('seos/list', fetcher);

    return {
      seo: Array.isArray(seo) ? seo[0] : null,
      isLoading: !error && !seo,
      isError: error,
    };
  };

  const useFullscreen = () => {
    const onHandleFullScreen = () => {
      setFullscreen((prevFullscreen: boolean) => !prevFullscreen);
    };

    return {
      fullScreen,
      onHandleFullScreen,
    };
  };

  const useCollectionCrumbs = () => {
    const onGameCardClick = ({ name, slug }: { name: string; slug: string }) => {
      setCurrentCollection({ name: name, slug: slug });
    };
    return {
      currentCollection,
      onGameCardClick,
    };
  };

  const appContextValues: AppContextInterface = {
    useConfig,
    useUser,
    useCategories,
    useSeo,
    // @ts-ignore Todo: HUZ2ERA-1111 - Can't seem to fix this type error. GameRequestProps data is mapped to GameProps using mappingGame(), but typescript compiler doesn't see that.
    useSearch,
    useCollections,
    useFullscreen,
    useCollectionCrumbs,
  };

  return <AppContext.Provider value={appContextValues} {...props} />;
};
