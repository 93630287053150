// Can't have enums in /types/index.d.ts, hence we put it in its own file.
export const enum CollectionTypesEnum {
  COLLECTION = 'collection',
  CATEGORY = 'category',
  BRAND = 'brand',
  SERIE = 'serie',
  TAG = 'tag',
}

export const enum LayoutTypeEnum {
  DEFAULT = 'default',
  CONSERVATIVE = 'conservative',
  MODERATE = 'moderate',
  AGGRESSIVE = 'aggressive',
}

export const enum PromotionBannerBackgroundEnum {
  BLUE = 'blue',
  RED = 'red',
}

export const enum PromotionBannerThemeEnum {
  DEFAULT = 'default',
  WINTER = 'winter',
}

export const enum GameLayoutAreaEnum {
  GAME = 'game',
  ADVERTISEMENT = 'advertisement',
  DESCRIPTION = 'description',
  WALKTHROUGH = 'walkthrough',
  CATEGORY = 'category',
  COLLECTION_LATEST = 'collection_latest',
  COLLECTION_POPULAR = 'collection_popular',
}

export const enum GameOrientationEnum {
  // COLLECTION = 'collection',
  // CATEGORY = 'category',
  // BRAND = 'brand',
  // SERIE = 'serie',
  // TAG = 'tag',
  PORTRAIT = 'portrait',
  PORTRAIT_PRIMARY = 'portrait-primary',
  PORTRAIT_SECONDARY = 'portrait-secondary',
  LANDSCAPE = 'landscape',
  LANDSCAPE_PRIMARY = 'landscape-primary',
  LANDSCAPE_SECONDARY = 'landscape-secondary',
  NATURAL = 'natural',
  ANY = 'any',
}

export const enum NavigationLayoutEnum {
  DEFAULT = 'default',
  ONE = 'navigation-below',
}

export const enum DrawerEnum {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
}

export const enum GameHeaderLayoutEnum {
  DEFAULT = 'default',
  MINIMAL = 'minimal',
  HIDDEN = 'hidden',
  FULL = 'full',
}

export const enum CardTypeEnum {
  DEFAULT = 'default',
  SIMPLE = 'simple',
  EXTENSIVE = 'extensive',
  PARTIAL = 'partial',
  BIG = 'big',
  CNN = 'cnn',
  BANNER = 'banner',
  CONTENTA = 'contentA',
  CONTENTB = 'contentB',
  CNNActivities = 'cnnactivities',
}

export enum UserRole {
  PARENT = 'owner',
  CHILD = 'kid',
}
