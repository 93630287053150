import type {
  CategoryMinimalRequestResultProps,
  CategoryRequestResultProps,
  GameMinimalRequestProps,
  CategoryRequestProps,
  GameRequestProps,
  GameImages,
} from '../types';

import { CollectionTypesEnum, GameOrientationEnum } from '../types/enum';
import { translateUrl } from 'next-translate-routes';

export const mappingGameImages = (game: GameMinimalRequestProps): GameImages => {
  return {
    cover: game?.cover?.id || null,
    cover1x1: game?.image?.cover1x1 || null,
    cover4x1: game?.image?.cover4x1 || game?.desktop?.id || null,
    cover4x3: game?.image?.cover4x3 || null,
    cover5x4: game?.image?.cover5x4 || null,
    cover7x3: game?.image?.cover7x3 || null,
    cover3x2: game?.image?.cover3x2 || null,
    cover16x10: game?.image?.cover16x10 || null,
    cover16x9: game?.image?.cover16x9 || game?.desktop?.id || null,
    cover32x9: game?.image?.cover32x9 || null,
    '512x512': game?.image?.['512x512'] || null,
    '1280x720': game?.image?.['1280x720'] || null,
    '1280x550': game?.image?.['1280x550'] || null,
    '512x384': game?.image?.['512x384'] || null,
    '200x120': game?.image?.['200x120'] || null,
  };
};

export const mappingGamesMinimal = (games: GameMinimalRequestProps[]) =>
  games?.map((game: GameMinimalRequestProps) => ({
    id: game.id,
    code: game.code,
    slug: game.code,
    publishedAt: game.publishedAt,
    name: game?.name || null,
    description: game?.description || null,
    company: game?.company || null,
    images: {
      ...mappingGameImages(game),
    },
  })) || [];

export const mappingGame = (game: GameRequestProps) => {
  return {
    id: game.id,
    gdGameId: game.gdGameId,
    code: game.code,
    slug: game.code,
    publishedAt: game.publishedAt,
    name: game?.name || null,
    description: game?.description || null,
    company: game?.company || null,
    src: game?.transfer?.source?.url || game?.runnable?.id || null,
    type: game?.transfer?.source?.type || game?.runnable?.id ? 'internal' : 'external',
    orientation: game?.orientation || GameOrientationEnum.ANY,
    meta: {
      title: game?.metatag?.title || null,
      description: game?.metatag?.description || null,
    },
    images: {
      ...mappingGameImages(game),
    },
    categories:
      game?.categories?.map((category: CategoryRequestResultProps) => ({
        ...mappingCollectionMinimal(category, CollectionTypesEnum.CATEGORY),
      })) || [],
    collections:
      game?.collections?.map((collection: CategoryRequestResultProps) => ({
        ...mappingCollectionMinimal(collection, CollectionTypesEnum.COLLECTION),
      })) || [],
    series:
      game?.series?.map((serie: CategoryRequestResultProps) => ({
        ...mappingCollectionMinimal(serie, CollectionTypesEnum.SERIE),
      })) || [],
    tags:
      game?.tags?.map((tag: CategoryRequestResultProps) => ({
        ...mappingCollectionMinimal(tag, CollectionTypesEnum.TAG),
      })) || [],
  };
};

export const mappingCollectionMinimal = (collection: CategoryMinimalRequestResultProps, type?: CollectionTypesEnum) => {
  return {
    id: collection.id,
    code: collection.code,
    slug: collection.code,
    type: collection?.type || type,
    name: collection?.name || null,
    images: {
      cover: collection?.icon?.id || null,
      cover1x1: collection?.icon?.id || null,
    },
  };
};

export const mappingCollection = (collection: CategoryRequestResultProps & { component?: any }) => {
  return {
    ...mappingCollectionMinimal(collection),
    games: [...mappingGamesMinimal(collection?.games || [])],
    component: collection?.component || null,
    weight: collection?.weight || null,
  };
};

export const mappingGameCrumbs = (game: GameRequestProps, slug: string | string[], locale: string) => {
  const url = `${process.env.PROJECT_URL?.replace(/\/+$/, '')}`;
  let crumbs = [{ href: url, path: '/', name: 'Home', type: '' }];
  if (game?.categories) {
    if (game.categories.length > 0 && game.categories[0]?.code && game.categories[0]?.name) {
      crumbs = [
        ...crumbs,
        {
          href: `${url}${translateUrl(`/category/${game.categories[0]?.code}`, locale)}`,
          path: `${translateUrl(`/category/${game.categories[0]?.code}`, locale)}`,
          name: game.categories[0].name || '',
          type: "category",
          // @ts-ignore
          code: game?.categories[0]?.code || '',
        },
      ];
    }
  }
  if (game?.collections) {
    if (game.collections.length > 0 && game.collections[0]?.code && game.collections[0]?.name) {
      crumbs = [
        ...crumbs,
        {
          href: `${url}${translateUrl(`/collection/${game.collections[0]?.code}`, locale)}`,
          path: `${translateUrl(`/collection/${game.collections[0]?.code}`, locale)}`,
          name: game.collections[0].name || '',
          type: "collection",
          // @ts-ignore
          code: game.collections[0].code,
        },
      ];
    }
  }
  crumbs = [
    ...crumbs,
    {
      href: `${url}${translateUrl(`/game/${slug}`, locale)}`,
      path: `${translateUrl(`/game/${slug}`, locale)}`,
      name: game.name || '',
      // @ts-ignore
      code: slug,
    },
  ];
  return crumbs;
};
